import React, { useState } from "react";
import Api from "./Api";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "react-bootstrap";
const Callback = () => {
  const apiurl = process.env.REACT_APP_API_URL;
  const history = useHistory();
  const { getAccessTokenSilently,  user } = useAuth0();
  const [error, setError] = useState(false);


  React.useEffect(() => {
    async function a() {
      try {
        const uri = `${apiurl}/users/${user.sub}`;
        let token = await getAccessTokenSilently();
        let result = await Api.GetApiData(uri, token, true);
        if (result.success) {
          if (result.data === null) {
            //create
            const create = `${apiurl}/users`;
            const data = { ExternalUserId: user.sub, Email: user.email };
            await Api.Post(create, token, data);
          }
          history.push("/");
        }
      } catch (e) {
        setError(e)
      }
    }

    a();
  });

  return (
    <div>
      {!error && <h1>Please wait, logging you in...</h1>}
      {error && <Alert variant="danger" >{error}</Alert>}
    </div>
  );
};

export default Callback;
