async function GetApiData(uri, token, escape404 = false) {
  let result = {}

  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(uri, {
        timeout: 100,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      result.success = response.ok ? true : false;
      result.data = await response.json();

      if (result.success) {
        resolve(result)
      } else {
        reject(result.data?.error)
      }

    } catch (e) {
      result.success = false;
      if (!e.response) {
        result.data = "Error: Network Error- Is the API running?";
      } else {
        result.data = e?.response?.data?.message;
      }
      reject(result.data);
    }
  });

  result = await promise;
  return result;
}

async function Post(url, token, data, method = "POST") {
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const promise = new Promise((resolve, reject) => {

    fetch(url, requestOptions)
      .then(console.log("request sent"))
      .then(async (response) => {
        let result = {};
        result.success = response.ok ? true : false;
        result.data = await response.json();

        if (result.success) {
          resolve(result);
        } else {
          reject(result);
        }

      })
      .catch((e) => {
        let result = {};
        result.success = false;

        if (!e.response) {
          result.data = "Error: Network Error- Is the API running?";
        } else {
          result.data = e?.response?.data?.message;
        }

        reject(result);
      });
  })

  const result = await promise;
  return result;
}

const ApiHelper = { GetApiData, Post }

export default ApiHelper;
