import React, {  useState } from "react";
import Api from "./Api";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Table, Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ClientList = () => {

    const [loading, setLoading] = React.useState(true);
    const [clients, setClients] = useState([]);
    const [error, setErrorState] = useState("");
    const history = useHistory()
    const { getAccessTokenSilently, user } = useAuth0();

    const create = async () => {
        history.push('/create/client')
    }

    const edit = async (id) => {
        history.push(`/edit/client/${id}`)
    }


    React.useEffect(() => {
        const url = process.env.REACT_APP_API_URL;
        document.title ="Manage clients"
        const getClients = async () => {
            try {

                const token = await getAccessTokenSilently();
                const result = await Api.GetApiData(`${url}/client/${user.sub}`, token)
                setErrorState("");

                if (result.success) {
                    setClients(result.data);
                } else {
                    setErrorState("Error occured when fetching clients...")
                }
            } catch (e) {
                setErrorState(e);
            } finally {
                setLoading(false);
            }
        };
        getClients();
    }, [getAccessTokenSilently,  user.sub])

    return (
        <>
            <Container fluid>
                <div className="row mt-2 mb-2 ">
                    <div className="col-12 d-flex justify-content-center">
                        <h1>Clients</h1>
                        <button type="button" className="btn btn-success ms-5" onClick={create} >Create new</button>
                    </div>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Client</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients && clients.map((client, idx) => (
                            // return (
                            <tr key={client.Id}>
                                <td>{idx + 1}</td>
                                <td>{client.Name}</td>
                                <td>
                                    {/* <Button
                                        onClick={() => remove(client.Id)}
                                        variant="danger"
                                    >
                                        Delete
                                    </Button>{" "} */}

                                    <Button
                                        onClick={async () => await edit(client.Id)}
                                        variant="secondary"
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                            // );
                        ))}


                    </tbody>
                </Table>

                {!loading && !error && clients.length === 0 && <div style={{ margin: 'auto', paddingTop: '10%', textAlign: 'center', paddingLeft: '10px' }}><h1>No clients found!</h1></div>}
                {loading && <div style={{ margin: 'auto', paddingTop: '10%', textAlign: 'center', paddingLeft: '10px' }}><h1>Fetching clients....</h1></div>}
            </Container>
        </>
    )
}

export default ClientList;