import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth0 } from '@auth0/auth0-react';
import * as Yup from 'yup';
import Api from './Api';
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import { Alert } from 'react-bootstrap';

const CreateClient = () => {
    const [client, setClient] = useState({
        Name: '',
        Email: '',
        ClientCode: '',
        ContactNumber: '',
        ClientDescription: '',
        PaymentTerms: '',
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        Postcode: '',
        City: '',
        County: '',
        Country: '',
    });

    const formik = useFormik({
        initialValues: client,
        validationSchema: Yup.object({
            Name: Yup.string()
                .min(3, "Must be 3 characters or more")
                .required("Required"),
            Email: Yup.string()
                .email("Invalid email address"),
            //.required("Required"),
            ClientCode: Yup.string()
                .max(3, "Must be 3 characters or less")
                .min(2, "Must be between 2 and 3 characters")
                .required("Required"),
            ClientDescription: Yup.string()
                .required("Required")
                .max(5000, "Must be 5000 characters or less"),
            PaymentTerms: Yup.number().required().positive().integer().max(365),
            ContactNumber: Yup.string()
                .min(10, "Must be atleast 10 numbers")
                .matches(/^\d+$/, "Only numbers are allowed"),
            //.required("Required"),
            Postcode: Yup.string(),
            //.required("Required"),
            City: Yup.string(),
            //.required("Required"),
            County: Yup.string(),
            //.required("Required"),
            Country: Yup.string()
            //.required("Required"),
        }),
        onSubmit: async (values) => {
            const token = await getAccessTokenSilently();
            try {
                let data = { ...values };

                //set empty's to nulls
                Object.keys(data).forEach(function (key) {
                    if (data[key] === '')
                        data[key] = null
                });

                if (clientid) {
                    await Api.Post(`${apiurl}/client`, token, { ...data, UserId: user.sub }, "PUT")
                    setsuccess("Client updated");
                } else {
                    await Api.Post(`${apiurl}/client`, token, { ...data, UserId: user.sub }, "POST")
                    setsuccess("Client created");
                }

                setServerError("");

            } catch (e) {
                setServerError(e);
                setsuccess("");
            }

            //setSubmitting(false);
        },
    });

    const { getAccessTokenSilently,  user } = useAuth0();
    //const [formState, setFormState] = useState(true);
    const [serverError, setServerError] = useState("");
    const [success, setsuccess] = useState("");

    const { clientid } = useParams();
    const apiurl = process.env.REACT_APP_API_URL;

    React.useEffect(() => {
        async function getClient() {
            try {
                if (clientid) {
                    const uri = `${apiurl}/client/single?userid=${user.sub}&clientid=${clientid}`;
                    const token = await getAccessTokenSilently();
                    let result = await Api.GetApiData(uri, token);
                    if (result.success) {
                        let data = result.data;
                        Object.keys(data).forEach(function (key) {
                            if (data[key] === null) {
                                data[key] = ''
                            }
                        });
                        setClient(data);
                        formik.setValues(data)
                        setServerError("");
                    } else {
                        setServerError("Error occured when retriving client");
                    }

                }


            } catch (e) {
                setServerError(e);
                setsuccess("");
            }
        }

        getClient();
    // eslint-disable-next-line
    }, [apiurl, clientid, getAccessTokenSilently, user.sub])

    

    return (
        <div>
            <Container>

                {serverError && <Alert className='mt-2' variant="danger">{serverError}</Alert>}
                {success && <Alert className='mt-2' variant="success">{success}</Alert>}


                <Form onSubmit={formik.handleSubmit}>

                    {!clientid && <h1 className="header text-center">Create new client</h1>}
                    {clientid && <h1 className="header text-center">Edit client</h1>}


                    <Row className="mt-2">
                        <Form.Label column="lg" md={2}>Name</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="Name"
                                type="text"
                                {...formik.getFieldProps('Name')}
                                className={formik.errors.Name ? 'is-invalid' : ''}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.Name && formik.errors.Name ? (
                                    <div className="text-danger">{formik.errors.Name}</div>
                                ) : null}
                            </Form.Text>
                        </Col>

                        <Form.Label column="lg" md={2}>Client code</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="ClientCode"
                                type="text"
                                {...formik.getFieldProps('ClientCode')}
                                className={formik.errors.ClientCode ? "is-invalid" : ""}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.ClientCode && formik.errors.ClientCode ? (
                                    <div className="text-danger">{formik.errors.ClientCode}</div>
                                ) : null}
                            </Form.Text>
                        </Col>
                    </Row>


                    <Row className="mt-2">
                        <Form.Label column="lg" md={2}>Description</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="ClientDescription"
                                type="text"
                                {...formik.getFieldProps('ClientDescription')}
                                className={formik.errors.ClientDescription ? "is-invalid" : ""}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.ClientDescription && formik.errors.ClientDescription ? (
                                    <div className="text-danger">{formik.errors.ClientDescription}</div>
                                ) : null}
                            </Form.Text>
                        </Col>

                        <Form.Label column="lg" md={2}>Payment Terms</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="PaymentTerms"
                                type="text"
                                {...formik.getFieldProps('PaymentTerms')}
                                className={formik.errors.PaymentTerms ? "is-invalid" : ""}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.PaymentTerms && formik.errors.PaymentTerms ? (
                                    <div className="text-danger">{formik.errors.PaymentTerms}</div>
                                ) : null}
                            </Form.Text>
                        </Col>
                    </Row>

                    <Row className="mt-2">

                        <Form.Label column="lg" md={2}>Email</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="Email"
                                type="text"
                                {...formik.getFieldProps('Email')}
                                className={formik.errors.Email ? "is-invalid" : ""}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.Email && formik.errors.Email ? (
                                    <div className="text-danger">{formik.errors.Email}</div>
                                ) : null}
                            </Form.Text>
                        </Col>
                        
                        <Form.Label column="lg" md={2}>Contact number</Form.Label>
                        <Col md={4}>
                            <Form.Control
                                size="lg"
                                name="ContactNumber"
                                type="text"
                                {...formik.getFieldProps('ContactNumber')}
                                className={formik.errors.ContactNumber ? "is-invalid" : ""}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.ContactNumber && formik.errors.ContactNumber ? (
                                    <div className="text-danger">{formik.errors.ContactNumber}</div>
                                ) : null}
                            </Form.Text>
                        </Col>
                    </Row>

                    <Card border="light" className='mt-2'>
                        <Card.Header as="h5">Address</Card.Header>
                        <Card.Body>
                            <Row>
                                <Form.Label column="lg" md={2}>Address line 1</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="AddressLine1"
                                        type="text"
                                        {...formik.getFieldProps('AddressLine1')}
                                    />
                                </Col>

                                <Form.Label column="lg" md={2}>Address line 2</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="AddressLine2"
                                        type="text"
                                        {...formik.getFieldProps('AddressLine2')}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-1'>
                                <Form.Label column="lg" md={2}>Address line 3</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="AddressLine3"
                                        type="text"
                                        {...formik.getFieldProps('AddressLine3')}
                                    />
                                </Col>
                            </Row>

                            <Row className='mt-1'>
                                <Form.Label column="lg" md={2}>Postcode</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="Postcode"
                                        type="text"
                                        {...formik.getFieldProps('Postcode')}
                                        className={formik.errors.Postcode ? "is-invalid" : ""}
                                    />
                                    <Form.Text className="text-danger">
                                        {formik.touched.Postcode && formik.errors.Postcode ? (
                                            <div className="text-danger">{formik.errors.Postcode}</div>
                                        ) : null}
                                    </Form.Text>
                                </Col>

                                <Form.Label column="lg" md={2}>City</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="City"
                                        type="text"
                                        {...formik.getFieldProps('City')}
                                        className={formik.errors.City ? "is-invalid" : ""}
                                    />
                                    <Form.Text className="text-danger">
                                        {formik.touched.City && formik.errors.City ? (
                                            <div className="text-danger">{formik.errors.City}</div>
                                        ) : null}
                                    </Form.Text>
                                </Col>
                            </Row>

                            <Row className='mt-1'>
                                <Form.Label column="lg" md={2}>County</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="County"
                                        type="text"
                                        {...formik.getFieldProps('County')}

                                        className={formik.errors.County ? "is-invalid" : ""}
                                    />
                                    <Form.Text className="text-danger">
                                        {formik.touched.County && formik.errors.County ? (
                                            <div className="text-danger">{formik.errors.County}</div>
                                        ) : null}
                                    </Form.Text>
                                </Col>

                                <Form.Label column="lg" md={2}>Country</Form.Label>
                                <Col md={4}>
                                    <Form.Control
                                        size="lg"
                                        name="Country"
                                        type="text"
                                        {...formik.getFieldProps('Country')}

                                        className={formik.errors.Country ? "is-invalid" : ""}
                                    />
                                    <Form.Text className="text-danger">
                                        {formik.touched.Country && formik.errors.Country ? (
                                            <div className="text-danger">{formik.errors.Country}</div>
                                        ) : null}
                                    </Form.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <div className="mt-2 mb-2 center-div">
                        <Button
                            variant="success"
                            type="submit"
                            size="lg"

                            //onClick={values => setFormState(values)}
                        >
                            Submit
                        </Button>

                        <Link to="/clients">
                            <Button
                                variant='secondary'
                                size='lg'
                                className='margin-left-1rem'>
                                Back
                            </Button>
                        </Link>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default CreateClient;