import React, { useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import FormControl from "react-bootstrap/Form";
import Card from 'react-bootstrap/Card'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Alert from "react-bootstrap/Alert";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./login";
import InvoiceNumber from "./invoicenumber";
import { Formik, useField, Form, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import { DateTime } from 'luxon';

const Invoice = () => {

  // let currencyformat = new Intl.NumberFormat("en-GB", {
  //   style: "currency",
  //   currency: "GBP",
  // });

  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const { invoicenumber } = useParams();
  // const [defaultCompany, setDefaultCompany] = React.useState("");


  const apiurl = process.env.REACT_APP_API_URL;

  const getApiData = useCallback(async (uri) => {
    try {
      let token = await getAccessTokenSilently();

      const response = await fetch(uri, {
        timeout: 100,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        console.error(response);
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }, [getAccessTokenSilently])


  // async function getApiData(uri) {
  //   try {
  //     let token = await getAccessTokenSilently();

  //     const response = await fetch(uri, {
  //       timeout: 100,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       return data;
  //     } else {
  //       console.error(response);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const [initialValues, setInitialValues] = useState({
    InvoiceNumber: '',
    InvoiceDate: DateTime.now().toJSDate(),
    ClientId: '',
    CompanyId: '',
    PaymentStatusId: '',
    PaymentTerms: '',
    Total: '',
    Notes: '',
    InvoiceDetails: [{
      Id: '',
      Description: '',
      Quantity: '',
      AmountPerUnit: '',
      VatPercentage: '20',
      VatAmount: '',
      SubTotal: '',
      Total: ''
    }]
  });

  React.useEffect(() => {

    async function getClients() {
      try {
        const uri = `${apiurl}/client/${user.sub}`;
        let data = await getApiData(uri);
        let items = [];
        if (data) {
          items.push({ Key: "", Value: "Please Select" });
          data.map((item) => items.push({ Key: item.Id, Value: item.Name }));
          setClientState(items);
          setClientLoading(false);

        } else {
          items.push({
            Key: "",
            Value: "Error fetching client. Try refreshing the page",
          });
          setClientState(items);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getCompany() {
      const uri = `${apiurl}/company/${user.sub}`;
      let data = await getApiData(uri);
      let items = [];
      if (data) {
        items.push({ Key: "", Value: "Please Select" });
        data.map((item) => items.push({ Key: item.Id, Value: item.Name }));
        setCompanyState(items);
        setCompanyLoading(false);


      } else {
        items.push({
          Key: "",
          Value: "Error fetching client. Try refreshing the page",
        });
        setCompanyState(items);
      }
    }

    async function getPaymentStatus() {
      try {
        const uri = `${apiurl}/paymentstatus`;
        let data = await getApiData(uri);
        let items = [];
        if (data) {
          items.push({ Key: "", Value: "Please Select" });
          data.map((item) => items.push({ Key: item.Id, Value: item.Name }));
          setPaymentStatus(items);
          setLoadingPaymentStatus(false);

          //set the default selection in the dropdown
          // const unpaid=data.find(f=>f.Name==="Unpaid");
          // console.log(unpaid);
          // setInvoiceState({
          //   ...invoicestate,
          //   ["paymentstatusid"]: { ...invoicestate["paymentstatusid"], value: unpaid.Id, isvalid: true },
          // })
        } else {
          items.push({
            Key: "",
            Value: "Error fetching payment status. Try refreshing the page",
          });
          setPaymentStatus(items);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const editMode = async () => {
      if (invoicenumber) {
        const token = await getAccessTokenSilently();
        var response = await fetch(`${apiurl}/invoice/${invoicenumber}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        let result = await response.json();

        let invoiceDetails = [];
        result.InvoiceDetails.forEach((detail, idx) => {
          invoiceDetails.push({
            Id: detail.Id,
            Description: detail.Description,
            Quantity: detail.Quantity,
            AmountPerUnit: detail.AmountPerUnit,
            VatPercentage: detail.VatPercentage,
            VatAmount: detail.VatAmount,
            SubTotal: detail.SubTotal,
            Total: detail.Total,
          })
        })

        let invoicedata =
        {
          "ClientId": result.ClientId,
          "InvoiceNumber": result.InvoiceNumber,
          "InvoiceDate": DateTime.fromFormat(result.InvoiceDate, 'yyyy-MM-dd').toJSDate(),
          "CompanyId": result.CompanyId,
          "PaymentTerms": result.PaymentTerms,
          "PaymentStatusId": result.PaymentStatusId,
          "Notes": result.Notes,
          "Total": result.Total,
          "InvoiceDetails": invoiceDetails,
        };

        //console.log(invoicedata);
        setInitialValues(invoicedata);

      }
    }

    getClients();
    getCompany();
    getPaymentStatus();
    editMode();
  }, [apiurl, getAccessTokenSilently, getApiData, invoicenumber, user.sub]);


  async function getClient(clientid) {
    try {
      if (clientid) {
        const uri = `${apiurl}/client/single?userid=${user.sub}&clientid=${clientid}`;
        let data = await getApiData(uri);
        return data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // const [invoicedetailstate, setInvoiceDetailState] = React.useState([
  //   { ...emptyInvoiceDetailTemplate },
  // ]);

  const [error, setErrorState] = React.useState("");
  // const [success, setSuccessState] = React.useState("");
  const [busy, setBusyState] = React.useState(false);

  const [clientItems, setClientState] = React.useState([
    {
      Value: "Loading ...",
      Key: "",
    },
  ]);

  const [companyItems, setCompanyState] = React.useState([
    {
      Value: isAuthenticated ? "Loading ..." : "User not authenticated",
      Key: "",
    },
  ]);

  const [paymentStatusItems, setPaymentStatus] = React.useState([
    {
      Value: isAuthenticated ? "Loading ..." : "User not authenticated",
      Key: "",
    },
  ]);

  const [loadingClient, setClientLoading] = React.useState(true);
  const [loadingCompany, setCompanyLoading] = React.useState(true);
  const [loadingPaymentStatus, setLoadingPaymentStatus] = React.useState(true);

  const addInvoiceDetail = (values, setValues) => {
    const details = [...values.InvoiceDetails];

    details.push({
      Id: '',
      Description: '',
      Quantity: '',
      AmountPerUnit: '',
      VatPercentage: '20',
      VatAmount: '',
      SubTotal: '',
      Total: ''
    });

    setValues({ ...values, "InvoiceDetails": details });
  };

  const removeInvoiceDetail = (index, values, setValues) => {
    const details = [...values.InvoiceDetails];
    details.splice(index, 1)
    console.log(details)
    setValues({ ...values, "InvoiceDetails": details });
  };

  const createOrUpdate = async (values) => {
    const body = { ...values, UserId: user.sub }

    const token = await getAccessTokenSilently();
    const requestOptions = {
      method: invoicenumber ? "PUT" : "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(body)
    };
    fetch(`${apiurl}/invoice`, requestOptions)
      .then(console.log("request sent"))
      .then(async (response) => {
        if (!response.ok) {
          var error = await response.json();
          throw Error(JSON.stringify(error));
        } else if (response.ok) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setErrorState(error.toString());
        manageLoadingState(false);
      });
  };

  const manageLoadingState = (loading) => {
    setBusyState(loading);
  };

  const MyDatePicker = ({ name = "" }) => {
    const [field, meta, helpers] = useField(name);

    const { value } = meta;
    const { setValue } = helpers;
    return (
      <>
        <DatePicker
          {...field}
          selected={value}
          dateFormat={"dd/MM/yyyy"}
          onChange={(date) => setValue(date)}
        />
        {meta.error && <div className="text-danger">{meta.error}</div>}

      </>
    );
  };


  const FormSelect = ({ name, options }) => {
    const [field, meta, helpers] = useField(name);
    const formikProps = useFormikContext()
    return (
      <>
        <FormControl.Select
          size="lg"
          name={name}
          {...field}
          onChange={(e) => {
            formikProps.setFieldValue(name, e.target.value);
          }}
          onBlur={() => helpers.setTouched(true)}
        >
          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </FormControl.Select>
        <span className="text-danger">
          {meta.error && meta.touched ? (
            <div>{meta.error}</div>
          ) : null}
        </span>
      </>
    );
  };


  async function fetchClient(clientid) {

    let value = ''
    const client = await getClient(clientid);

    if (client) {
      value = client.PaymentTerms;
    }

    return value;
  }

  const DependentField = (props) => {
    const {
      values: { ClientId },
      setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
      let isCurrent = true;
      // your business logic around when to fetch goes here.
      if (ClientId !== '' && !invoicenumber) {
        fetchClient(ClientId).then((c) => {
          if (!!isCurrent) {
            // prevent setting old values
            setFieldValue(props.name, c);
          }
        });
      }
      return () => {
        isCurrent = false;
      };
    }, [ClientId, props.name, setFieldValue]);

    return (
      <>
        <FormControl.Control
          column="lg"
          lg={2}
          {...props}
          {...field}

        />
        {!!meta.touched && !!meta.error && <div className="text-danger">{meta.error}</div>}
      </>
    );
  };

  const FieldV1 = (props) => {
    const [field, meta] = useField(props);
    return (
      <>
        <FormControl.Control
          column="lg"
          lg={2}
          {...props}
          {...field}
        />
        {!!meta.touched && !!meta.error && <div className="text-danger">{meta.error}</div>}
      </>
    );
  };

  let patternTwoDecimalPlaces = /^\d+(\.\d{0,2})?$/;
  const twodecimalplaces = (fieldname) => Yup
    .number(`${fieldname} should be number`)
    .test(
      "is-decimal",
      `${fieldname} should be upto 2 decimals`,
      (val) => {
        if (val !== undefined) {
          return patternTwoDecimalPlaces.test(val);
        }
        return true;
      }
    );

  const FormObserver = () => {
    const { values: { InvoiceDetails }, setFieldValue } = useFormikContext()

    React.useEffect(() => {
      //console.log("FormObserver::values", InvoiceDetails);

      let invoiceTotal = 0.00;
      for (let i = 0; i < InvoiceDetails.length; i++) {
        const element = InvoiceDetails[i];
        const quantity = element.Quantity;
        const amount = element.AmountPerUnit;
        const vatpercentage = element.VatPercentage
        // console.log(`quantity:${quantity}`)
        // console.log(`amount:${amount}`)
        // console.log(`vatpercentage:${vatpercentage}`)
        if (!isNaN(quantity) && !isNaN(amount) && !isNaN(vatpercentage)) {
          //console.log('inside not nan')
          let subtotal =  amount * quantity;
          subtotal = Number(subtotal.toFixed(2))
          let vatamount = (subtotal * vatpercentage) / 100;
          vatamount = Number(vatamount.toFixed(2))
          let total = subtotal + vatamount;
          total = Number(total.toFixed(2))

          setFieldValue(`InvoiceDetails[${i}].SubTotal`, subtotal);
          setFieldValue(`InvoiceDetails[${i}].VatAmount`, vatamount);
          setFieldValue(`InvoiceDetails[${i}].Total`, total);
          invoiceTotal += total;
        }
      }

      setFieldValue(`Total`, invoiceTotal.toFixed(2));
    }, [InvoiceDetails, setFieldValue]);

    return null;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        InvoiceNumber: Yup.string().required(),
        InvoiceDate: Yup.string().required(),
        ClientId: Yup.string().required(),
        CompanyId: Yup.string().required(),
        PaymentStatusId: Yup.string().required(),
        PaymentTerms: Yup.number().required().positive().integer().max(365),
        Notes: Yup.string().max(10),
        InvoiceDetails: Yup.array().of(
          Yup.object().shape({
            Description: Yup.string().max(500).required(),
            Quantity: twodecimalplaces("Quantity")
              .required("Quantity is a required field")
              .max(100000, "Maximum allowed Quantity is 100000"),
            AmountPerUnit: twodecimalplaces("AmountPerUnit")
              .required("Amount is a required field")
              .max(1000000, "Maximum allowed Amount is 1000000"),
            VatPercentage: Yup.number().positive().integer()
              .required("VatPercentage is a required field")
              .max(100, "Maximum allowed Percentage is 100"),
            VatAmount: Yup.number().required("VatAmount is a required field"),
            SubTotal: Yup.number().required("SubTotal is a required field"),
            Total: Yup.number().required("Total is a required field")
          })
        )
      })
      }
      onSubmit={async (values) => {
        try {
          //alert(JSON.stringify(values));
          manageLoadingState(true);
          await createOrUpdate(values);
        } catch (error) {
          manageLoadingState(false);
        }
      }}
    >

      {({ props, values, setValues }) =>
      (
        <Container>
          <Form>
            {!invoicenumber && <h1 className="header text-center">Create new invoice</h1>}
            {invoicenumber && <h1 className="header text-center">Edit invoice</h1>}

            {!isAuthenticated && <Login></Login>}
            {error && <Alert variant="danger">{error}</Alert>}

            {/* {success && <Alert variant="success">{success}</Alert>} */}

            <Row>
              {/* <Field name="InvoiceNumber" component={InvoiceNumberV3} /> */}
              <InvoiceNumber name="InvoiceNumber" readOnly={true} />

              <FormControl.Label column="lg" lg={2}>
                Date
              </FormControl.Label>
              <Col md={4}>
                <MyDatePicker name="InvoiceDate" />
              </Col>
            </Row>

            <Row>
              <FormControl.Label column="lg" lg={2}>
                Client
              </FormControl.Label>
              <Col>
                <FormSelect name="ClientId" disabled={loadingClient} options={clientItems.map((client) => ({ "value": client.Value, "key": client.Key }))} />
              </Col>
              <FormControl.Label column="lg" lg={2}>
                Company
              </FormControl.Label>
              <Col>
                <FormSelect name="CompanyId" disabled={loadingCompany} options={companyItems.map((company) => ({ "value": company.Value, "key": company.Key }))} />
              </Col>
            </Row>

            <Row className="mt-2">
              <FormControl.Label column="lg" lg={2}>
                Payment Terms
              </FormControl.Label>
              <Col>
                <DependentField name="PaymentTerms" type="text"></DependentField>
              </Col>

              <FormControl.Label column="lg" lg={2}>
                Notes
              </FormControl.Label>
              <Col>
                <FieldV1 name="Notes" as="textarea" rows="3" ></FieldV1>
              </Col>
            </Row>

            <Row className="mt-2">
              <FormControl.Label column="lg" lg={2}>
                Payment Status
              </FormControl.Label>
              <Col>
                <FormSelect name="PaymentStatusId" disabled={loadingPaymentStatus} options={paymentStatusItems.map((paymentstatus) => ({ "value": paymentstatus.Value, "key": paymentstatus.Key }))} />
              </Col>

              <FormControl.Label column="lg" lg={2}>
                Inv. Total
              </FormControl.Label>
              <Col>
                <FieldV1 name={`Total`} readOnly={true}></FieldV1>
              </Col>
            </Row>

            <FieldArray name="info">
              {() =>
                values.InvoiceDetails.map((invoicedetail, index) => {
                  return (
                    <Card className="mt-2" key={`detail-${index}`}>
                      <Card.Header as="h5" className="container-fluid">
                        Invoice details #{index + 1}

                        {index > 0 &&
                          <Button type='button' variant="danger" size="sm"
                            onClick={(e) => removeInvoiceDetail(index, values, setValues)}
                          >
                            Remove-{index}</Button>
                        }
                      </Card.Header>

                      <div className="mt-2 m-2">
                        <FormObserver />

                        <Row key={index}>
                          <FormControl.Label column="lg" lg={2}>
                            Description
                          </FormControl.Label>
                          <Col md={10}>
                            <FieldV1 name={`InvoiceDetails[${index}].Description`} ></FieldV1>
                          </Col>

                          <FormControl.Label column="lg" lg={2}>
                            Quantity
                          </FormControl.Label>
                          <Col md={4}>

                            <FieldV1 name={`InvoiceDetails[${index}].Quantity`}></FieldV1>
                            {/* <Field name={`InvoiceDetails[${index}].Quantity`} component={FieldV2} detail={invoicedetail} index={index} onChange={e => Calculate(index,invoicedetail)}/> */}
                          </Col>

                          <FormControl.Label column="lg" lg={2}>
                            Amount
                          </FormControl.Label>
                          <Col md={4}>
                            <FieldV1 name={`InvoiceDetails[${index}].AmountPerUnit`} ></FieldV1>
                          </Col>

                          <FormControl.Label column="lg" lg={2}>
                            Vat%
                          </FormControl.Label>
                          <Col lg={4}>
                            <FieldV1 name={`InvoiceDetails[${index}].VatPercentage`} ></FieldV1>
                          </Col>

                          <Row>
                            <FormControl.Label column="lg" lg={2}>
                              Sub total
                            </FormControl.Label>
                            <Col>
                              <FieldV1 readOnly={true} name={`InvoiceDetails[${index}].SubTotal`}></FieldV1>
                            </Col>

                            <FormControl.Label column="lg" lg={2}>
                              Vat Amount
                            </FormControl.Label>
                            <Col>
                              <FieldV1 readOnly={true} name={`InvoiceDetails[${index}].VatAmount`}></FieldV1>
                            </Col>

                            <FormControl.Label column="lg" lg={2}>
                              Total
                            </FormControl.Label>
                            <Col>
                              <FieldV1 readOnly={true} name={`InvoiceDetails[${index}].Total`} ></FieldV1>
                              {/* <Field name={`InvoiceDetails[${index}].Total`} component={FieldV2} onChange={e => Calculate(index,invoicedetail)}/> */}

                            </Col>
                          </Row>
                        </Row>
                      </div>
                    </Card>
                  )
                })
              }
            </FieldArray>

            <div className="mt-4 mb-4 center-div">
              <Button type="submit" variant="success" size="lg" disabled={busy}>
                {busy && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                )}
                Create Invoice
              </Button>
              <Button
                type="button"
                variant="secondary"
                size="lg"
                onClick={(e) => addInvoiceDetail(values, setValues)}
                disabled={busy}
                className='margin-left-1rem btn-block'
              >
                Add detail
              </Button>
              <Link to="/">
                <Button type="Button" variant="secondary" size="lg" disabled={busy} className='margin-left-1rem'>
                  Back
                </Button>
              </Link>
            </div>
          </Form>
        </Container>
      )}
    </Formik>
  );
};

export default Invoice;