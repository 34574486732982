import "./App.css";
import React from "react";
import Invoice from "./components/invoice";
import InvoiceList from "./components/invoicelist";
import Callback from "./components/callback";
import { Switch, Route } from "react-router-dom";
import { Nav, Navbar, Container } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./components/login";
import CreateClient from "./components/createclient";
import ClientList from "./components/clientlist";
import Sendinvoice from "./components/sendinvoice/sendinvoice";


const App = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Invoicer</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Manage invoices</Nav.Link>
            <Nav.Link href="/clients">Manage clients</Nav.Link>
          </Nav>
          {isAuthenticated && (
            <Nav>
              <Nav.Link
                onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URL_AFTER_LOGOUT })}
              >
                Logout
              </Nav.Link>
            </Nav>
          )}
        </Container>
      </Navbar>

      {isAuthenticated && (
        <div className="App">
          {/* <Header/> */}
          <Switch>
            <Route exact path="/">
              <InvoiceList />
            </Route>
            <Route exact path="/clients">
              <ClientList />
            </Route>
            <Route exact path="/create/client">
              <CreateClient />
            </Route>
            <Route exact path="/edit/client/:clientid">
              <CreateClient />
            </Route>
            <Route exact path="/new/invoice">
              <Invoice />
            </Route>
            <Route exact path="/edit/invoice/:invoicenumber">
              <Invoice />
            </Route>
            <Route exact path="/send/invoice/:invoicenumber">
              <Sendinvoice />
            </Route>
            <Route exact path="/callback">
              <Callback />
            </Route>
          </Switch>
        </div>
      )}

      {!isAuthenticated && <Login></Login>}
    </>
  );
};

export default App;
