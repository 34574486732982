import React from "react";
import Button from "react-bootstrap/esm/Button";
import { useAuth0 } from "@auth0/auth0-react";
import "./login.css";
const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="center">
      <div>
        <h1>Login to invoicing</h1>
        <Button
          type="button"
          variant="success"
          size="lg"
          onClick={() => {
            loginWithRedirect();
          }}
        >
          Login
        </Button>
      </div>
    </div>
    // <div class="box">
    //   <div>
    //     <h1>Login using Auth0</h1>
    //     <Button
    //       type="button"
    //       variant="success"
    //       size="lg"
    //       onClick={() => loginWithRedirect()}
    //     >
    //       Login
    //     </Button>
    //   </div>
    // </div>
  );
};

export default Login;
