import React, { useCallback, useState } from 'react';
import { Formik, useField, Form, FieldArray, Field } from 'formik';
import { Alert, Container } from 'react-bootstrap';
import FormControl from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAuth0 } from '@auth0/auth0-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const FieldV1 = (props) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormControl.Control
        column="lg"
        lg={2}
        {...props}
        {...field}
      />
      {!!meta.touched && !!meta.error && <div className="text-danger">{meta.error}</div>}
    </>
  );
};


const Sendinvoice = () => {

  const [loading, setLoading] = useState(true);
  const [errorState, setErrorState] = useState("");
  const [busy, setBusyState] = React.useState(false);
  const { invoicenumber } = useParams();
  const apiurl = process.env.REACT_APP_API_URL;

  const { getAccessTokenSilently, user } = useAuth0();

  const getApiData = useCallback(async (uri) => {
    try {
      let token = await getAccessTokenSilently();

      const response = await fetch(uri, {
        timeout: 100,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        setErrorState("Server error");
        return null;
      }
    } catch (e) {
      if (!e.response) {
        setErrorState("Error: Network Error- Is the API running?");
      } else {
        console.log(e);
        setErrorState("Error retriving data");
      }
    }
  }, [getAccessTokenSilently, errorState])

  React.useEffect(() => {

    const editMode = async () => {
      if (invoicenumber) {

        const emailsetting = await getApiData(`${apiurl}/emailsettings/${user.sub}/${invoicenumber}`)

        if(emailsetting){
          let invoicedata =
          {
            "To": emailsetting.EmailAddress,
            "Subject": emailsetting.Subject,
            "Body": emailsetting.EmailBodyTemplate,
            "Attachments": emailsetting.Attachments,
            "ClientName": emailsetting.ClientName
          };

          setInitialValues(invoicedata);
        }

        setLoading(false);

      }
    }

    editMode();

  }, [apiurl, getApiData, invoicenumber, user.sub])

  const [initialValues, setInitialValues] = useState({
    To: '',
    Subject: '',
    Body: '',
    ClientName: '',
    Attachments: [{
      FileId: '',
      FileName: ''
    }],
  });

  const manageLoadingState = (loading) => {
    setBusyState(loading);
  };

  const download = async (invoicenumber) => {

    var data = await getApiData(`${apiurl}/invoice/getLatestDocumentByInvoiceNumber/${invoicenumber}`);
    // 2. Create blob link to download
    const DocumentData = new Uint8Array(data.DocumentData.data);
    const blob = new Blob([DocumentData], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${data.FileName}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const saveEmailSettings = async (values) => {
    const body = { ...values, UserId: user.sub }
    const token = await getAccessTokenSilently();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(body)
    };
    fetch(`${apiurl}/emailsettings/createEmailSettingsFromInvoiceNumber/${invoicenumber}`, requestOptions)
      .then(console.log("request sent"))
      .then(async (response) => {
        if (!response.ok) {
          var error = await response.json();
          throw Error(JSON.stringify(error));
        } else if (response.ok) {
          // console.log('Settings saved!')
          window.location.href = "/";
        }
      })
      .catch((error) => {
        //setErrorState(error.toString());
        manageLoadingState(false);
      });
  }

  const send = async (values) => {
    manageLoadingState(true);
    const body = { ...values, UserId: user.sub }
    const token = await getAccessTokenSilently();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      body: JSON.stringify(body)
    };
    fetch(`${apiurl}/email/send`, requestOptions)
      .then(console.log("request sent"))
      .then(async (response) => {
        if (!response.ok) {
          var error = await response.json();
          throw Error(JSON.stringify(error));
        } else if (response.ok) {
          await saveEmailSettings(values);
        }
      })
      .catch((error) => {
        console.log(error)
        setErrorState(error.toString());
        manageLoadingState(false);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        To: Yup.string().required(),
        Subject: Yup.string().required(),
        Body: Yup.string().required(),
      })}
      onSubmit={async (values) => {
        //send email
        await send(values);
      }}
    >

      {({ props, values, setValues }) =>
      (
        <Container>
          <Form className='mt-2'>
            {errorState && <Alert variant="danger">{errorState}</Alert>}

            <h1 className="header text-center">#{invoicenumber} ({values.ClientName})</h1>

            {loading ? <p style={{ margin: 'auto', paddingTop: '10%', textAlign: 'center', paddingLeft: '10px' }}>Loading...</p> :

              <>

                <Row>
                  <FormControl.Label column="lg" lg={2}>
                    To
                  </FormControl.Label>
                  <Col md={10}>
                    <FieldV1 name="To" />
                  </Col>
                </Row>


                <Row>
                  <FormControl.Label column="lg" lg={2}>
                    Subject
                  </FormControl.Label>
                  <Col md={10}>
                    <FieldV1 name="Subject" />
                  </Col>
                </Row>

                <Row>
                  <FormControl.Label column="lg" lg={2}>
                    Body
                  </FormControl.Label>
                  <Col md={10}>
                    <Field name="Body">
                      {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <FormControl.Label column="lg" lg={2}>
                    Attachments
                  </FormControl.Label>

                  <Col md={10}>
                    <FieldArray name='attachments'>
                      {() =>
                        values.Attachments.map((attachment, index) => {
                          return (

                            <Row className='mt-2' key={`detail-${index}`}>

                              <Col md={5}>
                                <FieldV1 name={`Attachments[${index}].FileName`} disabled={true} />
                              </Col>
                              <Col md={2}>
                                <Button onClick={async () => await download(invoicenumber)} disabled={busy}> Download </Button>
                              </Col>
                            </Row>
                          );
                        })
                      }
                    </FieldArray>
                  </Col>
                </Row>

                <div className="mt-4 mb-4 center-div">
                  <Button type="submit" variant="success" size="lg" disabled={busy}>
                    {busy && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="visually-hidden">Loading...</span>
                      </>
                    )}
                    Send Invoice
                  </Button>

                  <Link to="/">
                    <Button type="Button" variant="secondary" size="lg" disabled={busy} className='margin-left-1rem'>
                      Back
                    </Button>
                  </Link>
                </div>
              </>
            }
          </Form>
        </Container>
      )}
    </Formik>)
};

Sendinvoice.propTypes = {};
Sendinvoice.defaultProps = {};
export default Sendinvoice;
