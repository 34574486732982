import React, {  useState } from 'react';
import FormControl from 'react-bootstrap/Form'

import Col from 'react-bootstrap/Col'
import Api from './Api';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useField, useFormikContext } from "formik";

const InvoiceNumberV4 = (props) => {

    const { getAccessTokenSilently } = useAuth0()
    const { invoicenumber } = useParams();
    const {setFieldValue} = useFormikContext()

    const [field, meta] = useField(props);
    const [serverError, setServerError] = useState('');

    React.useEffect(() => {
        const apiurl = process.env.REACT_APP_API_URL;

        async function a() {
            let invTemp = invoicenumber;

            try {

                if (invTemp) { }
                else {
                    //get next invoice number only on edit
                    const uri = `${apiurl}/invoice/getNextInvoiceNumber`;
                    let token = await getAccessTokenSilently();
                    let result = await Api.GetApiData(uri, token);
                    invTemp = result.data.InvoiceNumber
                    setFieldValue("InvoiceNumber", invTemp)
                }

            } catch (error) {
                setServerError(error);
            }
        }
        a();
    }, [invoicenumber, getAccessTokenSilently, setFieldValue])


    return (
        <>
            <FormControl.Label column="lg" lg={2}>
                Inv. number
            </FormControl.Label>
            <Col md={4}>
                <FormControl.Control
                    column="lg"
                    lg={2}
                    {...field}
                    {...props}
                />
                {!serverError && !!meta.touched && !!meta.error && <div className="text-danger">{meta.error}</div>}
                {serverError && <div className="text-danger">{serverError}</div>}

            </Col>

        </>
    );
}

export default InvoiceNumberV4;