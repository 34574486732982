import React, { useCallback } from "react";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import Api from './Api';
const InvoiceList = () => {

  const history = useHistory();

  const { user, getAccessTokenSilently } = useAuth0();
  const [error, setErrorState] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const url = process.env.REACT_APP_API_URL;


  const getInvoices = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const result = await Api.GetApiData(`${url}/invoice/u/${user.sub}`, token)
      setErrorState("");

      if (result.success) {
        setInvoices(result.data);
      }
    } catch (e) {
      setErrorState(e);
    }
    setLoading(false);
  }, [getAccessTokenSilently, url,user.sub]);

  const copy = async (invoicenumber) => {
    try {
      const token = await getAccessTokenSilently();
      const requestOptions =
      {
        "method": "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
      }
      var response = await fetch(
        `${url}/invoice/copy/${invoicenumber}`, requestOptions
      );

      if (response.ok) {
        getInvoices();
      } else {
        setErrorState(`${response.status}-${response.statusText}`);
      }
    } catch (e) {
      setErrorState(e);
    }
  }

  const regenerate = async (invoicenumber) => {
    try {
      const token = await getAccessTokenSilently();
      const requestOptions =
      {
        "method": "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }
      }
      var response = await fetch(
        `${url}/invoice/generate/${invoicenumber}`, requestOptions
      );

      if (response.ok) {
        download(invoicenumber)
      } else {
        setErrorState(`${response.status}-${response.statusText}`);
      }
    } catch (e) {
      setErrorState(e);
    }
  }

  const edit = async (invoicenumber) => {    
    history.push(`/edit/invoice/${invoicenumber}`)
  }

  const create = async () => {
    history.push("/new/invoice")
  }

  const download = async (invoicenumber) => {
    setErrorState("");
    const token = await getAccessTokenSilently();
    var response = await fetch(
      `${url}/invoice/getLatestDocumentByInvoiceNumber/${invoicenumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      var data = await response.json();
      // 2. Create blob link to download
      const DocumentData = new Uint8Array(data.DocumentData.data);
      const blob = new Blob([DocumentData], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${data.FileName}.pdf`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    } else {
      console.log(response);
      setErrorState(`${response.status}-${response.statusText}`);
    }
  };

  const send = async (invoicenumber) => {
    history.push(`/send/invoice/${invoicenumber}`)
  }

  React.useEffect(() => {
    getInvoices();
    document.title = "Manage invoices";
  }, [getInvoices]);

  const [invoices, setInvoices] = React.useState([]);

  const onDelete = async (id) => {
    let r = window.confirm("Are you sure you wish to delete this item?");
    if (r === true) {
      const token = await getAccessTokenSilently();
      var response = await fetch(
        `${url}/invoice/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        await getInvoices();
      } else {
        setErrorState(`${response.status}-${response.statusText}`);
      }
    }
  };

  const getColor = (overdue) => {
    if (overdue){
      return '#E26310';
    } else{
      return '';
    }
  };

  return (
    <Container fluid>
      <div className="row mt-2">
        <div className="col-12 d-flex justify-content-md-center">
          <h1>Invoices</h1>
          <button type="button" className="btn btn-success ms-5" onClick={create} >Create new</button>
        </div>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <Table responsive="sm">
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Invoice No.</th>
            <th>Client</th>
            <th>Inv. Date</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          {invoices && invoices.map((invoice) => {
            return (
              <tr key={invoice.Id} style={{ "backgroundColor": getColor(invoice.PaymentOverdue) }}  >
                <td>{invoice.InvoiceNumber}</td>
                <td>{invoice.Client.Name}</td>
                <td>{invoice.InvoiceDate}</td>
                <td>{invoice.Total}</td>
                <td>
                  
                  <Button
                    onClick={async () => await edit(invoice.InvoiceNumber)}
                    variant="secondary"
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    onClick={async () => await send(invoice.InvoiceNumber)}
                    variant="secondary"
                  >
                    Send Invoice
                  </Button> {" "}

                  <Button
                    onClick={async () => await download(invoice.InvoiceNumber)}
                    variant="secondary"
                  >
                    Download
                  </Button> {" "}

                  <Button
                    onClick={async () => await copy(invoice.InvoiceNumber)}
                    variant="secondary"
                  >
                    Copy
                  </Button> {" "}

                  <Button
                    onClick={async () => await regenerate(invoice.InvoiceNumber)}
                    variant="secondary"
                  >
                    Regenerate Inv.
                  </Button>{" "}
                 
                  <Button
                    onClick={() => onDelete(invoice.Id)}
                    variant="secondary"
                  >
                    Delete
                  </Button>{" "}
                </td>
              </tr>
            );
          })}


        </tbody>
      </Table>

      {!loading && !error && invoices.length === 0 && <div style={{ margin: 'auto', paddingTop: '10%', textAlign: 'center', paddingLeft: '10px' }}><h1>No invoices found!</h1></div>}
      {loading && <div style={{ margin: 'auto', paddingTop: '10%', textAlign: 'center', paddingLeft: '10px' }}><h1>Fetching invoices....</h1></div>}


    </Container >
  );
};

export default InvoiceList;
